import api from '../../lib/api'

export default {
    namespaced: true,
    state: {
        list: [],
        names: [],
        loading: false,
        types: [
            { id: 1, name: "Юридическое лицо", short: "Юрлицо" },
            { id: 2, name: "Индивидуальный предприниматель", short: "ИП" },
            { id: 3, name: "Физическое лицо", short: "Физлицо" },
        ],
        emptyItem: {
            id: 0,
            type: 1,
            name: "",
            email: "",
            phone: "",
            address: "",
            inn: "",
            kpp: "",
            ogrn: "",
            managerName: "",
            managerPost: "",
            notes: "",
            files: [],
            newFiles: [],
            status: 1,
            loaded: false,
        }
    },
    getters: {
        list: state => state.list,
        names: state => state.names,
        loading: state => state.loading,
        loaded: state => state.suppliers?.length > 0,
        types: state => state.types,
        emptyItem: state => state.emptyItem,
    },
    mutations: {
        clear(state) {
            state.list = []
            state.names = []
        },
        setList(state, list) {
            state.list = list
        },
        setNames(state, names) {
            state.names = names
        },
        startLoading(state) {
            state.loading = true
        },
        stopLoading(state) {
            state.loading = false
        },
    },
    actions: {
        clear(context) {
            context.commit('clear')
        },
        getNames(context) {
            context.commit('startLoading')
            return api.post("suppliers/get_names").then((response) => {
                context.commit('setNames', response.data.items)
                context.commit('stopLoading')
            })
        },
        getList(context) {
            context.commit('startLoading')
            return api.post("suppliers/get_list").then((response) => {
                context.commit('setList', response.data.items)
                context.commit('setNames', response.data.items.map((item) => ({
                    id: item.id,
                    name: item.name
                })))
                context.commit('stopLoading')
            })
        },
    }
}