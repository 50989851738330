import api from '../../lib/api'

export default {
    namespaced: true,
    state: {
        list: [],
        loading: false
    },
    getters: {
        list: state => state.list,
        loading: state => state.loading,
        loaded: state => state.list.length > 0,
    },
    mutations: {
        setList(state, list) {
            state.list = list
        },
        startLoading(state) {
            state.loading = true
        },
        stopLoading(state) {
            state.loading = false
        },
    },
    actions: {
        getList(context, force = false) {
            // Валюты загружаются только один раз, при первом обращении к ним
            // Если force = true, то загрузка будет произведена в любом случае
            if (context.getters.loaded && !force) return

            context.commit('startLoading')
            api.post("currencies/get_list").then((response) => {
                context.commit('setList', response.data.currencies)
                context.commit('stopLoading')
            })
        }
    }
}