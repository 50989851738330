import api from '../../lib/api'

export default {
    namespaced: true,
    state: {
        confirm: {
            open: false,
            title: '',
            message: '',
            btnAcceptText: '',
            btnDenyText: ''
        },
        superConfirm: {
            open: false,
            title: '',
            message: '',
            check: '',
            btnAcceptText: '',
            btnDenyText: ''
        },
        notify: {
            open: false,
            title: '',
            message: '',
            duration: 0,
            btnAcceptText: '',
        },
        // image: {
        //     open: false,
        //     src: '',
        //     name: '',
        // },
        connection: {
            open: false,
        },
        maintenance: {
            open: false,
        },
        export: {
            open: false,
            name: '',
            message: '',
            rows: null,
            styles: null,
            loading: false
        },
    },
    getters: {
        confirm: state => state.confirm,
        superConfirm: state => state.superConfirm,
        notify: state => state.notify,
        // image: state => state.image,
        connection: state => state.connection,
        maintenance: state => state.maintenance,
        export: state => state.export,
    },
    mutations: {
        set(state, params) {
            state[params.name] = Object.assign(state[params.name], params.dialog)
        },
    },
    actions: {
        confirmOpen(context, dialog) {
            dialog.open = true
            dialog.title = dialog.title || ''
            dialog.message = dialog.message || ''
            dialog.btnAcceptText = dialog.btnAcceptText || 'Ок'
            dialog.btnDenyText = dialog.btnDenyText || 'Назад'

            context.commit('set', {
                name: 'confirm',
                dialog: dialog
            })

            return new Promise((resolve, reject) => {
                this.dialogConfirmResolve = resolve
                this.dialogConfirmReject = reject
            })
        },
        confirmAccept(context) {
            this.dialogConfirmResolve(true)
            context.commit('set', {
                name: 'confirm',
                dialog: {
                    open: false,
                    title: '',
                    message: ''
                }
            })
        },
        confirmDeny(context) {
            this.dialogConfirmResolve(false)
            context.commit('set', {
                name: 'confirm',
                dialog: {
                    open: false,
                    title: '',
                    message: ''
                }
            })
        },
        superConfirmOpen(context, dialog) {
            dialog.open = true
            dialog.title = dialog.title || ''
            dialog.message = dialog.message || ''
            dialog.check = dialog.check || 'УДАЛИТЬ'
            dialog.btnAcceptText = dialog.btnAcceptText || 'Ок'
            dialog.btnDenyText = dialog.btnDenyText || 'Назад'

            context.commit('set', {
                name: 'superConfirm',
                dialog: dialog
            })

            return new Promise((resolve, reject) => {
                this.superConfirmResolve = resolve
                this.superConfirmReject = reject
            })
        },
        superConfirmAccept(context) {
            this.superConfirmResolve(true)
            context.commit('set', {
                name: 'superConfirm',
                dialog: {
                    open: false,
                    title: '',
                    check: '',
                    message: ''
                }
            })
        },
        superConfirmDeny(context) {
            this.superConfirmResolve(false)
            context.commit('set', {
                name: 'superConfirm',
                dialog: {
                    open: false,
                    title: '',
                    check: '',
                    message: ''
                }
            })
        },
        notifyOpen(context, dialog) {
            dialog.open = true
            dialog.title = dialog.title || ''
            dialog.message = dialog.message || ''
            dialog.duration = dialog.duration || 0
            dialog.btnAcceptText = dialog.btnAcceptText || 'Ок'

            context.commit('set', {
                name: 'notify',
                dialog: dialog
            })

            if (dialog.duration > 0) {
                setTimeout(() => {
                    this.dispatch('notifyClose')
                }, dialog.duration)
            }

            return new Promise((resolve) => {
                this.notifyResolve = resolve
            })
        },
        notifyClose(context) {
            if (this.notifyResolve) {
                this.notifyResolve(true)
            }
            context.commit('set', {
                name: 'notify',
                dialog: {
                    open: false,
                    title: '',
                    message: ''
                }
            })
        },
        // imageOpen(context, dialog) {
        //     dialog.open = true
        //     dialog.src = dialog.src || ''
        //     dialog.name = dialog.name || ''

        //     context.commit('set', {
        //         name: 'image',
        //         dialog: dialog
        //     })
        // },
        // imageClose(context) {
        //     context.commit('set', {
        //         name: 'image',
        //         dialog: {
        //             open: false,
        //             src: '',
        //             name: ''
        //         }
        //     })
        // },
        // imageDownload(context) {
        //     const link = document.createElement("a")
        //     link.href = context.state.image.src
        //     link.target = "_blank"
        //     link.setAttribute("download", context.state.image.name)
        //     document.body.appendChild(link)
        //     link.click()
        //     document.body.removeChild(link)
        // },
        connectionOpen(context) {
            context.commit('set', {
                name: 'connection',
                dialog: { open: true }
            })
        },
        connectionClose(context) {
            context.commit('set', {
                name: 'connection',
                dialog: { open: false }
            })
        },
        maintenanceOpen(context) {
            context.commit('set', {
                name: 'maintenance',
                dialog: { open: true }
            })
        },
        maintenanceClose(context) {
            context.commit('set', {
                name: 'maintenance',
                dialog: { open: false }
            })
        },
        exportOpen(context, params) {
            context.commit('set', {
                name: 'export',
                dialog: {
                    open: true,
                    name: params.name,
                    message: params.message,
                    rows: params.rows,
                    styles: params.styles,
                    loading: false
                }
            })
        },
        exportConfirm(context, params) {
            var path = ''
            var encoding = ''
            var ext = ''

            if (params.name !== undefined) {
                context.commit('set', {
                    name: 'export',
                    dialog: {
                        name: params.name
                    }
                })
            }

            if (params.rows !== undefined) {
                context.commit('set', {
                    name: 'export',
                    dialog: {
                        rows: params.rows
                    }
                })
            }

            if (params.styles !== undefined) {
                context.commit('set', {
                    name: 'export',
                    dialog: {
                        styles: params.styles
                    }
                })
            }

            if (params.format == 'xlsx') {
                path = 'download/get_excel'
                ext = '.xlsx'
            }
            else {
                path = 'download/get_csv'
                encoding = params.format.slice(4)
                ext = '.csv'
            }

            context.commit('set', {
                name: 'export',
                dialog: {
                    loading: true
                }
            })

            api.post(path, {
                rows: context.state.export.rows,
                styles: context.state.export.styles,
                encoding: encoding
            }, {
                responseType: 'blob'
            })
                .then(response => {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(response.data)

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a')
                    link.href = href
                    link.setAttribute('download', context.state.export.name + ext)
                    document.body.appendChild(link)
                    link.click()

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link)
                    URL.revokeObjectURL(href)

                    context.commit('set', {
                        name: 'export',
                        dialog: {
                            open: false,
                            name: '',
                            message: '',
                            rows: null,
                            loading: false
                        }
                    })
                })
        },
        exportClose(context) {
            context.commit('set', {
                name: 'export',
                dialog: {
                    open: false,
                    name: '',
                    message: '',
                    rows: null,
                    loading: false
                }
            })
        },
    }
}