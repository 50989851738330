import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
	icons: {
		iconfont: 'mdiSvg',
	},
	theme: {
		themes: {
			light: {
				primary: '#01579B',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
			},
		},
	},
	breakpoint: {
		thresholds: {
			xs: 480,
		},
	},
}

export default new Vuetify(opts)
