import { toNum } from './common.js'

export function required(v) {
    if (v instanceof Array) {
        return v.length > 0 || 'Обязательно'
    }
    else {
        return !!v || 'Обязательно'
    }
}
export function positive(v) {
    return toNum(v) > 0 || 'Больше нуля'
}
export function nonNegative(v) {
    return toNum(v) >= 0 || 'Больше или равно нулю'
}
export function email(v) {
    // Валидный email или пустая строка
    const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(v) || v === '' || "Некорректный email"
}
export function minEight(v) {
    return v.length >= 8 || "Минимум 8 символов"
}
export function hasNumber(v) {
    return /\d/.test(v) || "Должен содержать цифру"
}
export function hasLowercase(v) {
    return /[a-z]/.test(v) || "Должен содержать строчную букву"
}
export function hasUppercase(v) {
    return /[A-Z]/.test(v) || "Должен содержать заглавную букву"
}
export function hasLetter(v) {
    return /[A-z]/.test(v) || "Должен содержать букву"
}
export function hasSpecial(v) {
    return /[!@#\$%\^\&*\)\(+=._-]/.test(v)
}