import api from '../../lib/api'

export default {
    namespaced: true,
    state: {
        materials: [],
        products: [],
        resources: [],
        loading: {
            materials: false,
            products: false,
            resources: false,
        }
    },
    getters: {
        // Categories
        materials: state => state.materials,
        products: state => state.products,
        resources: state => state.resources,
        // Loading status
        loadingMaterials: state => state.loading.materials,
        loadingProducts: state => state.loading.products,
        loadingResources: state => state.loading.resources,
        // Loaded status
        loadedMaterials: state => state.materials.length > 0,
        loadedProducts: state => state.products.length > 0,
        loadedResources: state => state.resources.length > 0,
    },
    mutations: {
        setMaterials(state, materials) {
            state.materials = materials
        },
        setProducts(state, products) {
            state.products = products
        },
        setResources(state, resources) {
            state.resources = resources
        },
        startLoading(state, type) {
            state.loading[type] = true
        },
        stopLoading(state, type) {
            state.loading[type] = false
        },
        clear(state) {
            state.materials = []
            state.products = []
            state.resources = []
        }
    },
    actions: {
        clear(context) {
            context.commit('clear')
        },
        getMaterials(context, force = false) {
            // Категории только один раз, при первом обращении к ним
            // Если force = true, то загрузка будет произведена в любом случае
            if (context.getters.loadedMaterials && !force) return

            // Если загрузка уже идет, то ничего не делаем
            if (context.getters.loadingMaterials) return

            context.commit('startLoading', 'materials')
            api.post("materials/get_categories").then((response) => {
                context.commit('setMaterials', response.data.categories)
                context.commit('stopLoading', 'materials')
            })
        },
        getProducts(context, force = false) {
            // Категории только один раз, при первом обращении к ним
            // Если force = true, то загрузка будет произведена в любом случае
            if (context.getters.loadedProducts && !force) return

            // Если загрузка уже идет, то ничего не делаем
            if (context.getters.loadingProducts) return

            context.commit('startLoading', 'products')
            api.post("products/get_categories").then((response) => {
                context.commit('setProducts', response.data.categories)
                context.commit('stopLoading', 'products')
            })
        },
        getResources(context, force = false) {
            // Категории только один раз, при первом обращении к ним
            // Если force = true, то загрузка будет произведена в любом случае
            if (context.getters.loadedResources && !force) return

            // Если загрузка уже идет, то ничего не делаем
            if (context.getters.loadingResources) return

            context.commit('startLoading', 'resources')
            api.post("resources/get_categories").then((response) => {
                context.commit('setResources', response.data.categories)
                context.commit('stopLoading', 'resources')
            })
        },
    }
}