<template>
	<v-app>
		<!-- Меню слева -->
		<v-navigation-drawer
			v-if="showMenu"
			app
			v-model="drawer"
			width="300px"
			color="blue-grey lighten-5"
			hide-overlay
			:expand-on-hover="!menuFix && !mobile"
			:mini-variant.sync="menuMini"
		>
			<!-- Логотип -->
			<v-toolbar flat height="80px" color="blue-grey lighten-5">
				<template v-if="menuMini">
					<v-toolbar-title class="pt-1">
						<router-link to="/materials">
							<v-img
								max-height="24"
								max-width="24"
								src="img/c.svg"
								style="cursor: pointer"
							></v-img>
						</router-link>
					</v-toolbar-title>
				</template>
				<template v-else>
					<v-toolbar-title class="pt-1">
						<router-link to="/materials">
							<v-img
								max-height="50"
								max-width="150"
								:src="nyLogo ? '/img/logo-ny.svg' : '/img/logo.svg'"
								style="cursor: pointer"
							></v-img>
						</router-link>
					</v-toolbar-title>
				</template>
			</v-toolbar>
			<!-- Главное меню -->
			<v-list
				v-if="user && this.$store.getters['storages/loadedNames']"
				nav
				:style="mobile ? 'padding-bottom: 150px;' : ''"
			>
				<!-- Материалы -->
				<v-list-item v-if="user.materialsView" to="/materials" @click="trackMenuClick('/materials')">
					<v-list-item-icon>
						<v-icon>mdi-barley</v-icon>
					</v-list-item-icon>
					<v-list-item-content> Материалы </v-list-item-content>
				</v-list-item>

				<!-- Продукты -->
				<v-list-item v-if="user.productsView" to="/products" @click="trackMenuClick('/products')">
					<v-list-item-icon>
						<v-icon>mdi-package-variant-closed</v-icon>
					</v-list-item-icon>
					<v-list-item-content> Продукты </v-list-item-content>
				</v-list-item>

				<v-divider class="my-2"></v-divider>

				<!-- Поставки -->
				<v-list-group
					v-if="user.purchasesView"
					v-model="menuExpandPurchases"
					mandatory
					@click="openGroup('/purchases')"
					class="mb-2 text--primary"
				>
					<template v-slot:activator>
						<v-list-item-icon>
							<v-icon>mdi-truck</v-icon>
						</v-list-item-icon>
						<v-list-item-content> Поставки </v-list-item-content>
					</template>
					<v-list-item to="/purchases" @click="trackMenuClick('/purchases')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Поставки </v-list-item-content>
					</v-list-item>
					<v-list-item to="/suppliers" @click="trackMenuClick('/suppliers')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Поставщики </v-list-item-content>
					</v-list-item>
				</v-list-group>

				<!-- Производство -->
				<v-list-item
					v-if="user.productionView"
					to="/production"
					@click="trackMenuClick('/production')"
				>
					<v-list-item-icon>
						<v-icon>mdi-hammer-wrench</v-icon>
					</v-list-item-icon>
					<v-list-item-content> Производство </v-list-item-content>
				</v-list-item>

				<!-- Заказы -->
				<v-list-group
					v-if="user.ordersView"
					v-model="menuExpandOrders"
					mandatory
					@click="openGroup('/orders')"
					class="mb-2 text--primary"
				>
					<template v-slot:activator>
						<v-list-item-icon>
							<v-icon>mdi-shopping</v-icon>
						</v-list-item-icon>
						<v-list-item-content> Заказы </v-list-item-content>
					</template>
					<v-list-item to="/orders" @click="trackMenuClick('/orders')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Заказы </v-list-item-content>
					</v-list-item>
					<v-list-item to="/customers" @click="trackMenuClick('/customers')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Покупатели </v-list-item-content>
					</v-list-item>
				</v-list-group>

				<!-- Другое -->
				<v-list-group v-if="menuShowMore" v-model="menuExpandMore" class="mb-2 text--primary">
					<template v-slot:activator>
						<v-list-item-icon>
							<v-icon>mdi-dots-horizontal-circle</v-icon>
						</v-list-item-icon>
						<v-list-item-content>Другое</v-list-item-content>
					</template>

					<!-- Склады -->
					<v-list-item v-if="user.storagesView" to="/storages" @click="trackMenuClick('/storages')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Склады </v-list-item-content>
					</v-list-item>

					<!-- Перемещения -->
					<v-list-item v-if="menuShowTransfers" to="/transfer" @click="trackMenuClick('/transfer')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Перемещения </v-list-item-content>
					</v-list-item>

					<!-- Списания -->
					<v-list-item
						v-if="user.writeoffsView"
						to="/writeoff"
						@click="trackMenuClick('/writeoff')"
					>
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Списания </v-list-item-content>
					</v-list-item>

					<!-- Ресурсы -->
					<v-list-item
						v-if="user.resourcesView"
						to="/resources"
						@click="trackMenuClick('/resources')"
					>
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Ресурсы </v-list-item-content>
					</v-list-item>

					<!-- Инвентаризации -->
					<v-list-item v-if="user.auditsView" to="/audits" @click="trackMenuClick('/audits')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Инвентаризации </v-list-item-content>
					</v-list-item>

					<!-- История -->
					<v-list-item v-if="user.historyView" to="/history" @click="trackMenuClick('/history')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> История </v-list-item-content>
					</v-list-item>

					<!-- Отчеты -->
					<v-list-item v-if="user.reportsView" to="/reports" @click="trackMenuClick('/reports')">
						<v-list-item-icon> </v-list-item-icon>
						<v-list-item-content class="pl-4"> Отчеты </v-list-item-content>
						<v-list-item-icon>
							<v-chip small label color="primary" text-color="white" class="mr-2"> NEW </v-chip>
						</v-list-item-icon>
					</v-list-item>
				</v-list-group>

				<v-divider class="my-2"></v-divider>

				<!-- Настройки -->
				<v-list-item to="/settings" @click="trackMenuClick('/settings')">
					<v-list-item-icon>
						<v-icon>mdi-cog</v-icon>
					</v-list-item-icon>
					<v-list-item-content class=""> Настройки </v-list-item-content>
				</v-list-item>

				<!-- База знаний -->
				<v-list-item
					href="https://help.controlata.ru/?utm_source=app&utm_content=menu"
					target="_blank"
					@click="trackMenuClick('/help')"
				>
					<v-list-item-icon>
						<v-icon>mdi-book-open-variant</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class=""> База знаний </v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- Панель администратора -->
				<v-list-item
					v-if="$store.getters.userGlobalAdmin && !hideAdmin"
					key="/admin"
					to="/admin"
					@dblclick="hideAdmin = true"
				>
					<v-list-item-icon>
						<v-icon>mdi-shield-account</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class=""> Панель администратора </v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- Вы зашли как -->
				<v-list-item v-if="$store.getters.adminToken">
					<v-card class="my-3" width="100%">
						<v-card-text>
							Вы зашли как:
							{{ $store.getters.userCompanyName }}
						</v-card-text>
						<v-card-actions class="pa-3">
							<v-btn small block color="primary" elevation="0" @click="exitViewAs">
								Вернуться
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-list-item>
			</v-list>
			<!-- Скелетон лоадер меню -->
			<v-col v-else>
				<v-skeleton-loader v-for="i in 10" :key="i" class="pa-4" type="text"></v-skeleton-loader>
			</v-col>
			<!-- Булавка "Закрепить меню" -->
			<template v-slot:append>
				<v-row v-if="$vuetify.breakpoint.lgAndUp" class="justify-start pa-3">
					<v-col cols="auto" class="pa-3">
						<v-tooltip right open-delay="500">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									text
									small
									v-bind="attrs"
									v-on="on"
									:ripple="false"
									@click="toggleMenuFix"
								>
									<v-icon small>
										{{ menuFix ? "mdi-pin" : "mdi-pin-outline" }}
									</v-icon>
								</v-btn>
							</template>
							<span>{{ menuFix ? "Открепить меню" : "Закрепить меню" }}</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</template>
		</v-navigation-drawer>
		<!-- Рабочая область -->
		<v-main>
			<!-- Тулбар про окончание пробного периода -->
			<v-toolbar
				v-if="showMenu && showToolbarTrialIsEnding"
				flat
				class="amber lighten-3 d-print-none"
				height="60px"
			>
				<v-row class="justify-center">
					<v-col cols="auto" class :class="'caption flex-grow-1 text-center'">
						{{ toolbarTrialDaysText }}
						<br />
						Пожалуйста,
						<a @click="openPricing">выберите тарифный план</a>, чтобы и дальше пользоваться
						сервисом
					</v-col>
				</v-row>
			</v-toolbar>
			<!-- Тулбар про оплату счета -->
			<v-toolbar
				v-if="showMenu && showToolbarInvoiceIsDue"
				flat
				class="amber lighten-3 d-print-none"
				height="60px"
			>
				<v-row class="justify-center">
					<v-col cols="auto" class :class="'caption flex-grow-1 text-center'">
						Пожалуйста,
						<a @click="openInvoices">оплатите счет</a>, чтобы и дальше пользоваться сервисом
					</v-col>
				</v-row>
			</v-toolbar>
			<!-- Тулбар про перезагрузку страницы -->
			<v-toolbar
				v-if="showMenu && showToolbarReload"
				flat
				class="amber lighten-3 d-print-none"
				height="60px"
			>
				<v-row class="justify-center">
					<v-col cols="auto" class :class="'caption flex-grow-1 text-center'">
						Мы обновили сервис. Пожалуйста,
						<a @click="$router.go()">перезагрузите</a>
						страницу для корректной работы.
					</v-col>
				</v-row>
			</v-toolbar>
			<!-- Тулбар с названием страницы в мобильном -->
			<v-toolbar flat v-show="showMenu && !$vuetify.breakpoint.lgAndUp" class="d-print-none">
				<v-app-bar-nav-icon :value="!drawer" @click.native="drawer = !drawer"></v-app-bar-nav-icon>
				<v-row class="justify-center">
					<v-col cols="auto" class="font-weight-bold mr-7">
						{{ $router.currentRoute.meta.title }}
					</v-col>
				</v-row>
			</v-toolbar>
			<!-- Контент -->
			<router-view ref="content" id="content"></router-view>
		</v-main>
		<!-- Диалог уведомления универсальный -->
		<v-dialog v-model="this['dialog/notify'].open" max-width="500" persistent style="z-index: 100000">
			<v-card>
				<v-card-title class="headline">
					{{ this["dialog/notify"].title }}
				</v-card-title>

				<v-card-text>
					<span v-html="this['dialog/notify'].message" class="text--primary"> </span>
				</v-card-text>

				<v-card-actions class="pa-4">
					<v-btn color="primary" elevation="0" @click="this['dialog/notifyClose']"> Закрыть </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Диалог подтверждения универсальный -->
		<v-dialog
			v-model="this['dialog/confirm'].open"
			max-width="500"
			ref="confirm"
			persistent
			style="z-index: 100000"
		>
			<v-card>
				<v-card-title class="headline">
					{{ this["dialog/confirm"].title }}
				</v-card-title>

				<v-card-text>
					<span v-html="this['dialog/confirm'].message" class="text--primary"> </span>
				</v-card-text>

				<v-card-actions class="pa-4">
					<v-btn color="primary" elevation="0" @click="this['dialog/confirmAccept']">
						{{ this["dialog/confirm"].btnAcceptText }}
					</v-btn>
					<v-btn outlined @click="this['dialog/confirmDeny']">
						{{ this["dialog/confirm"].btnDenyText }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Диалог подтверждения с вводом слова -->
		<v-dialog
			v-model="this['dialog/superConfirm'].open"
			max-width="500"
			ref="super-confirm"
			persistent
			style="z-index: 100000"
		>
			<v-card>
				<v-card-title class="headline">
					{{ this["dialog/superConfirm"].title }}
				</v-card-title>

				<v-card-text>
					<span v-html="this['dialog/superConfirm'].message" class="text--primary"> </span>
				</v-card-text>

				<v-card-text>
					<span class="text--primary">
						Для подтверждения введите текст "{{ this["dialog/superConfirm"].check }}" в поле ниже
					</span>
				</v-card-text>

				<v-card-text>
					<v-form v-model="superConfirmValid" ref="superConfirmForm">
						<v-text-field
							v-model="superConfirmCheck"
							outlined
							:rules="[(v) => v == this['dialog/superConfirm'].check || 'Текст не совпадает']"
						></v-text-field>
					</v-form>
				</v-card-text>

				<v-card-actions class="pa-4">
					<v-btn color="primary" elevation="0" @click="dialogSuperConfirmAccept">
						{{ this["dialog/superConfirm"].btnAcceptText }}
					</v-btn>
					<v-btn outlined @click="dialogSuperConfirmDeny">
						{{ this["dialog/superConfirm"].btnDenyText }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Диалог проблемы с сетью -->
		<v-dialog
			v-model="this['dialog/connection'].open"
			max-width="500"
			overlay-color="white"
			:overlay-opacity="0.7"
			persistent
		>
			<v-card>
				<v-card-title class="headline"> Проблемы с сетью </v-card-title>

				<v-card-text> Похоже, что-то случилось с соединением с интернетом </v-card-text>

				<v-card-actions class="pa-4">
					<v-btn color="primary" elevation="0" @click="this['dialog/connectionClose']">
						Закрыть
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Диалог выбора тарифа после триала -->
		<v-dialog
			:value="user?.pastdue && user?.plan == 'trial'"
			max-width="500"
			:fullscreen="mobile"
			persistent
		>
			<v-card>
				<v-card-title class="headline"> Выберите тарифный план </v-card-title>

				<v-card-text>
					Пробный период использования сервиса закончился. Пожалуйста, выберите тарифный план, чтобы
					и дальше пользоваться сервисом.
				</v-card-text>
				<v-card-text>
					Если вам нужно больше времени для принятия решения, пожалуйста, напишите нам в чат.
				</v-card-text>

				<v-card-text>
					<a-settings-payment></a-settings-payment>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- Диалог просрочки оплаты -->
		<v-dialog
			:value="user?.pastdue && user?.plan != 'trial'"
			max-width="1100"
			:fullscreen="mobile"
			persistent
		>
			<v-card>
				<v-card-title class="headline"> Обновите платежные данные </v-card-title>

				<v-card-text v-if="user?.paymentMethod == 'card'">
					Мы не смогли списать очередную оплату с вашей карты. Пожалуйста внесите оплату, чтобы и
					дальше пользоваться вашим тарифным планом.
				</v-card-text>
				<v-card-text v-else-if="user?.paymentMethod == 'invoice'">
					Мы не получили оплату по выставленному счету. Пожалуйста внесите оплату, чтобы и дальше
					пользоваться вашим тарифным планом.
					<br /><br />
					Если вы уже оплатили счет, то пожалуйста, напишите нам в чат и пришлите копию платежного
					поручения.
				</v-card-text>

				<v-card-text>
					<a-settings-payment></a-settings-payment>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- Диалог экспорта -->
		<v-dialog
			v-model="this['dialog/export'].open"
			max-width="400"
			ref="export"
			@keydown.esc="dialogExportClose"
		>
			<v-card>
				<v-card-title class="headline"> Экспорт </v-card-title>

				<v-card-text class="pt-4 text-body-1 text--primary">
					{{ this["dialog/export"].message }}
				</v-card-text>

				<v-card-text class="pt-4">
					<v-select
						label="Формат"
						v-model="exportParams.format"
						:items="exportParams.formats"
						outlined
						hide-details
					></v-select>
				</v-card-text>

				<v-card-actions class="pa-4">
					<v-btn
						color="primary"
						elevation="0"
						:loading="this['dialog/export'].loading"
						@click="dialogExportConfirm"
					>
						Загрузить
					</v-btn>
					<v-btn outlined @click="dialogExportClose"> Отмена </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Диалог технических работ -->
		<v-dialog
			v-model="this['dialog/maintenance'].open"
			max-width="500"
			overlay-color="white"
			:overlay-opacity="1"
			persistent
		>
			<v-card>
				<v-card-title class="headline"> Технические работы 🛠 </v-card-title>

				<v-card-text>
					На сайте проводятся технические работы. Мы скоро все починим. Приносим извинения за
					неудобства.
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- Снэкбары -->
		<template v-if="!mobile" v-for="(s, i) in snackbar">
			<v-snackbar
				:value="true"
				bottom
				right
				timeout="-1"
				color="primary"
				class="d-print-none"
				:style="'margin-bottom: ' + (22 + 62 * i) + 'px;' + 'margin-right: 100px;'"
			>
				{{ s.text }}
				<template v-slot:action="{ attrs }">
					<v-btn v-bind="attrs" @click="snackbar.splice(i, 1)" icon>
						<v-icon small>mdi-close</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
		</template>
	</v-app>
</template>

<style>
.internal-link {
	cursor: pointer;
	text-decoration: none;
}
.internal-link:hover {
	text-decoration: underline;
}
</style>

<style scoped>
.head-logo {
	padding-left: -10px;
}
.head-logo:hover {
	cursor: pointer;
}
</style>

<style>
.no-hover-table tr:hover {
	background-color: transparent !important;
}
html {
	overflow-y: auto;
}
</style>

<script>
import api from "./lib/api"
import { track } from "@amplitude/analytics-browser"
import { mapGetters, mapActions } from "vuex"
import { daysSuffix } from "./lib/common"
import aSettingsPayment from "./components/settings/ASettingsPayment.vue"

export default {
	components: {
		aSettingsPayment,
	},
	data: () => ({
		drawer: false,
		toolbar: false,
		annual: true,
		superConfirmValid: false,
		superConfirmCheck: "",
		hideAdmin: false,
		menuFix: true,
		menuMini: false,
		menuExpandPurchases: false,
		menuExpandOrders: false,
		menuExpandMore: false,
		exportParams: {
			valid: false,
			format: "xlsx",
			formats: [
				{
					value: "xlsx",
					text: "Excel",
				},
				{
					value: "csv-windows-1251",
					text: "CSV",
				},
				{
					value: "csv-utf-8",
					text: "CSV (UTF-8)",
				},
			],
		},
	}),
	computed: {
		mobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		menuShowTransfers() {
			if (!this.user.transfersView) return false
			else if (this["storages/materialsCount"] > 1) return true
			else if (this["storages/productsCount"] > 1) return true
			else return false
		},
		menuShowMore() {
			return (
				this.user.storagesView ||
				this.menuShowTransfers ||
				this.user.writeoffsView ||
				this.user.resourcesView ||
				this.user.auditsView ||
				this.user.historyView ||
				this.user.reportsView
			)
		},
		trialDaysText() {
			if (!this.user) return ""
			else return this.user.trialDays + " " + daysSuffix(this.user.trialDays)
		},
		toolbarTrialDaysText() {
			let days = this.user?.trialDays

			if (days > 1) {
				return "Осталось " + this.trialDaysText + " пробного периода"
			} else if (days == 1) {
				return "Остался 1 день пробного периода"
			} else {
				return "Пробный период закончился"
			}
		},
		unusedTrialDaysText() {
			let days = this.user?.trialDays

			if (days == 1 || days == 21) {
				return "неиспользованный " + this.trialDaysText
			} else {
				return "неиспользованные " + this.trialDaysText
			}
		},
		showToolbarTrialIsEnding() {
			return (
				this.user?.plan == "trial" && // тариф trial
				this.user?.trialDays <= 7 // осталось меньше недели
			)
		},
		showToolbarInvoiceIsDue() {
			if (!this.user) return false
			if (this.user.paymentMethod != "invoice") return false
			if (this.user.pastdue) return false

			// check if nextPaymentDate is in the next three days
			var now = new Date()
			var nextPaymentDate = new Date(this.user?.nextPaymentDate)
			var diff = nextPaymentDate - now
			var days = Math.floor(diff / 1000 / 60 / 60 / 24)

			return days <= 3
		},
		showToolbarReload() {
			return this.$store.getters.needReload
		},
		printPage() {
			return this.$route.path.includes("/print/")
		},
		showMenu() {
			return (
				this.authorized &&
				!this.$route.path.includes("/print/") &&
				!this.$route.path.includes("/onboarding")
			)
		},
		nyLogo() {
			var now = new Date()
			var start = new Date(now.getFullYear(), 0, 0)
			var diff = now - start
			var day = Math.floor(diff / 1000 / 60 / 60 / 24)
			return day < 15 || day > 350
		},
		...mapGetters([
			"storages/materialsCount",
			"storages/productsCount",
			"storages/loaded",
			"authorized",
			"dialog/notify",
			"dialog/confirm",
			"dialog/superConfirm",
			"dialog/export",
			"dialog/connection",
			"dialog/maintenance",
			"dialog/pastdue",
			"snackbar",
			"user",
		]),
	},
	watch: {
		menuMini(val) {
			if (val) {
				this.menuExpandPurchases = false
				this.menuExpandOrders = false
				this.menuExpandMore = false
			}
		},
	},
	mounted() {
		window.app = this
		this.drawer = this.$vuetify.breakpoint.lgAndUp
		this.menuFix = this.user ? this.user.menuFix : true
	},
	methods: {
		dialogSuperConfirmAccept() {
			this.$refs.superConfirmForm.validate()

			if (this.superConfirmValid && this.superConfirmCheck == this["dialog/superConfirm"].check) {
				this.$store.dispatch("dialog/superConfirmAccept")
				this.$refs.superConfirmForm.reset()
			}
		},
		dialogSuperConfirmDeny() {
			this.$store.dispatch("dialog/superConfirmDeny")
			this.$refs.superConfirmForm.reset()
		},
		dialogExportConfirm() {
			this.$store.dispatch("dialog/exportConfirm", {
				format: this.exportParams.format,
			})
		},
		dialogExportClose() {
			// Почему-то не работает @keydown.esc="this['dialog/...']"
			this.$store.dispatch("dialog/exportClose")
		},
		openGroup(path) {
			if (!this.mobile && this.$route.path !== path) {
				this.$router.push(path)
			}
		},
		toggleMenuFix() {
			this.menuFix = !this.menuFix
			this.$nextTick(() => {
				this.menuMini = false
			})

			api.post("user/menu_fix", {
				menuFix: this.menuFix,
			})
		},
		trackMenuClick(path) {
			track("MENU_CLICK", {
				path: path.slice(1),
			})
		},
		openPricing() {
			if (this.$route.name !== "Settings") {
				this.$router.push({ name: "Settings", params: { openPricing: true } })
			} else {
				this.$refs.content.$refs.payment.openPricing()
			}
		},
		openInvoices() {
			if (this.$route.name !== "Settings") {
				this.$router.push({ name: "Settings", params: { openInvoices: true } })
			} else {
				this.$refs.content.$refs.payment.openInvoices()
			}
		},
		exitViewAs() {
			let viewAsToken = localStorage.getItem("token")
			let token = this.$store.getters.adminToken

			this.$store.dispatch("clear")

			localStorage.setItem("token", token)

			this.$store.dispatch("set", {
				key: "adminToken",
				value: "",
			})

			this.$store.dispatch("loadInitialData", true)

			api.post("admin/delete_view_as_token", {
				token: viewAsToken,
			}).then(() => {
				this.$router.push("/admin/companies")
			})
		},
		...mapActions([
			"dialog/confirmAccept",
			"dialog/confirmDeny",
			"dialog/notifyOpen",
			"dialog/notifyClose",
			"dialog/exportClose",
			"dialog/connectionClose",
		]),
	},
}
</script>
