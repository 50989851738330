export function numberFormat(number, decimals, dec_point, thousands_sep) {
	number = (number + "").replace(/[^0-9+\-Ee.]/g, "")
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = typeof thousands_sep === "undefined" ? " " : thousands_sep,
		dec = typeof dec_point === "undefined" ? "." : dec_point,
		s = "",
		toFixedFix = function (n, prec) {
			var k = Math.pow(10, prec)
			return "" + (Math.round(n * k) / k).toFixed(prec)
		}
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".")
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
	}
	if ((s[1] || "").length < prec) {
		s[1] = s[1] || ""
		s[1] += new Array(prec - s[1].length + 1).join("0")
	}
	return s.join(dec)
	// .replace(/(.*\..*?)(0+)$/,'$1');
}
export function toNum(string) {
	let number = (string + "").replace(',', '.').replace(/\s/g, '')
	return +number
}
export function toStr(number, decimals) {
	decimals = decimals === undefined ? 2 : decimals
	return numberFormat(number, decimals)
}
export function materialsSuffix(len) {
	// Удалить Х материалов
	// 2 - 4 материала
	// 5 - 20 материалов
	// 21 материал
	// 22 - 24 материала
	// 25 - 30 материалов
	// 31 материал
	// 32 - 34 материала
	// 35 - 40 материалов

	if (len >= 5 && len <= 20) {
		return "материалов"
	}
	else if (len % 10 == 1) {
		return "материал"
	}
	else if (len % 10 >= 2 && len % 10 <= 4) {
		return "материала"
	}
	else {
		return "материалов"
	}
}
export function productsSuffix(len) {
	// Удалить Х продуктов
	// 2 - 4 продукта
	// 5 - 20 продуктов
	// 21 продукт
	// 22 - 24 продукта
	// 25 - 30 продуктов
	// 31 продукт
	// 32 - 34 продукта
	// 35 - 40 продуктов

	if (len >= 5 && len <= 20) {
		return "продуктов"
	}
	else if (len % 10 == 1) {
		return "продукт"
	}
	else if (len % 10 >= 2 && len % 10 <= 4) {
		return "продукта"
	}
	else {
		return "продуктов"
	}
}
export function resourcesSuffix(len) {
	// Удалить Х ресурсов
	// 2 - 4 ресурса
	// 5 - 20 ресурсов
	// 21 ресурс
	// 22 - 24 ресурса
	// 25 - 30 ресурсов
	// 31 ресурс
	// 32 - 34 ресурса
	// 35 - 40 ресурсов

	if (len >= 5 && len <= 20) {
		return "ресурсов"
	}
	else if (len % 10 == 1) {
		return "ресурс"
	}
	else if (len % 10 >= 2 && len % 10 <= 4) {
		return "ресурса"
	}
	else {
		return "ресурсов"
	}
}
export function customersSuffix(len) {
	// Удалить / объединить Х покупателей
	// 2 - 4 покупателей
	// 5 - 20 покупателей
	// 21 покупателя
	// 22 - 24 покупателя
	// 25 - 30 покупателей
	// 31 покупателя
	// 32 - 34 покупателя
	// 35 - 40 покупателей

	if (len >= 2 && len <= 20) {
		return "покупателей"
	}
	else if (len % 10 == 1) {
		return "покупателя"
	}
	else if (len % 10 >= 2 && len % 10 <= 4) {
		return "покупателей"
	}
	else {
		return "покупателей"
	}
}
export function suppliersSuffix(len) {
	// Удалить / объединить Х поставщиков
	// 2 - 4 поставщиков
	// 5 - 20 поставщиков
	// 21 поставщика
	// 22 - 24 поставщика
	// 25 - 30 поставщиков
	// 31 поставщика
	// 32 - 34 поставщика
	// 35 - 40 поставщиков

	if (len >= 2 && len <= 20) {
		return "поставщиков"
	}
	else if (len % 10 == 1) {
		return "поставщика"
	}
	else if (len % 10 >= 2 && len % 10 <= 4) {
		return "поставщиков"
	}
	else {
		return "покупателей"
	}
}
export function daysSuffix(len) {
	// Пробный период закончится через Х дней
	// 1 день
	// 2 - 4 дня
	// 5 - 20 дней
	// 21 день
	// 22 - 24 дня
	// 25 - 30 дней
	// 31 день
	// 32 - 34 дня
	// 35 - 40 дней

	if (len >= 5 && len <= 20) {
		return "дней"
	}
	else if (len % 10 >= 2 && len % 10 <= 4) {
		return "дня"
	}
	else if (len % 10 == 1) {
		return "день"
	}
	else {
		return "дней"
	}
}
export function monthsSuffix(len) {
	// Срок подписки - Х месяцев
	// 1 месяц
	// 2 - 4 месяца
	// 5 - 20 месяцев
	// 21 месяц
	// 22 - 24 месяца
	// 25 - 30 месяцев
	// 31 месяц
	// 32 - 34 месяца
	// 35 - 40 месяцев

	if (len >= 5 && len <= 20) {
		return "месяцев"
	}
	else if (len % 10 >= 2 && len % 10 <= 4) {
		return "месяца"
	}
	else if (len % 10 == 1) {
		return "месяц"
	}
	else {
		return "месяцев"
	}
}
export function orderNum(num) {
	// check if number is integer
	if (typeof num === 'number' && Number.isInteger(num) && num >= 0 && num <= 9999) {
		return num.toString().padStart(4, "0")
	}
	else {
		return num
	}
}